import React, { useState } from 'react'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter';

import { env } from '../../api/api';
import { humanReadableBytes } from '../../helper/Helper'

import alertStore, { alert } from '../../stores/alertStore'

const PodImpro = () => {
  const { sessionStore, podStore, opStore, uploadStore } = useStore()
  const [ minimized, setMinimized ] = useState(true)
  const { session } = sessionStore

  const uploadImg = (e) => {
    if (e.target && e.target.files) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          if (fileData) uploadStore.add(`${env.backendBaseUrl}/uploadAsset`, 'B.1cca0o.2tR.1.1', {
            assetId: sessionStore.createUuid(),
            type: 'podImage',
            rel: '',
            size: file.size,
          }, fileData, 'application/jpg', () => { console.log('success') }, () => { console.log('failure') })
        }
        e.target.value = ''
      }
      reader.readAsArrayBuffer(file)
    }
    return false
  }

  const podStats = (pod) => {
    if (podStore.podCondition[pod.podId]?.status === 'broken') return null
    return <div>
      Contains&nbsp;
        {pod.getPdfFiles().length} files and&nbsp;
        {pod.getPdfFiles().map(f => f.annotations ? Object.keys(f.annotations).length : 0).reduce((acc, f) => acc+=f, 0)} Annotations,&nbsp;
        {pod.getPdfFiles().map(f => f.comments ? Object.keys(f.comments).length : 0).reduce((acc, f) => acc+=f, 0)} Comments,&nbsp;
        {pod.getPdfFiles().map(f => f.links ? Object.keys(f.links).length : 0).reduce((acc, f) => acc+=f/2, 0)} Links,&nbsp;
        {pod.getPdfFiles().map(f => f.weblinks ? Object.keys(f.weblinks).length : 0).reduce((acc, f) => acc+=f, 0)} Weblinks.
        Weighs in at { humanReadableBytes(JSON.stringify({...pod}).length) }. The fingerprint is: <pre style={{backgroundColor:'#fff', 'border': '1px solid #000', display:'inline-block', padding:'0 4px'}}>{ podStore.pod.fingerprint() }</pre>
    </div>
  }

  const loadbuttons = (pod) => {
    if (podStore.podCondition[pod.podId]?.status !== 'broken') {
      return <span key={pod.podId}>
        <button key='load' onClick={async() => {
          const result = await podStore.loadPod(pod.podId)
          if (!result) alertStore.push(alert(`Pod ${pod.podId} could not be loaded.`, 'error'))
        }} title='Load this pod and make it active'>
          [ Call podLoad({pod.podId}) ]
        </button>
        &nbsp;
      </span>
    } else {

      return <span key={pod.podId}>
        <button key='load' onClick={async() => {
          const result = await podStore.loadPod(pod.podId)
          if (!result) alertStore.push(alert(`Pod ${pod.podId} could not be loaded.`, 'error'))
        }} title={`${podStore.podCondition[pod.podId]?.info}`}>
          [ <s>Call podLoad({pod.podId})</s> ]
        </button>
        <span>&nbsp;</span>
        <button onClick={async() => {
          await podStore.resetPod(pod.podId)
        }}>Reset</button>
        &nbsp;
      </span>
    }
  }

  let currentPodInfo = '---'
  //if ((podStore.pod?.podId) && (podStore.podCondition[podStore.pod?.podId]?.status !== 'broken')) {
  if ((podStore.pod?.podId)) {
    const podId = podStore.pod.podId
    currentPodInfo  = `${podId}`
    currentPodInfo += ' ('
    currentPodInfo += `${podStore.podCondition[podId]?.status}`
    if (podStore.pod.status === 'loading') currentPodInfo += ` ${podStore.pod.loadStatus}%`
    currentPodInfo += ')'
  }

  if (minimized) return <div onClick={() => { setMinimized(!minimized) }} style={{border:'1px solid #00a', backgroundColor:'#e4e4ff', padding:4, position:'absolute', bottom:0}}>DEV</div>

  return <div style={{border:'1px solid #00a', backgroundColor:'#e4e4ff', padding:4, position:'absolute', bottom:0}}>
    <span onClick={() => { setMinimized(!minimized) }}>MIN</span>
    { podStore.pod?.podId ? <div><button onClick={() => { opStore.syncFull() }}>Call syncFull()</button></div> : null }
    { session.pods.map((pod) => loadbuttons(pod)) }
    <br /><Link to="/shrimPdfReader">ShrimPdf Reader</Link><input type="file" onChange={uploadImg} /><br />
    <h1>Currently loaded: {currentPodInfo}</h1>
    { podStore.pod?.podId ? podStats(podStore.pod) : null }
    <Link to="/pod/p-0000-0001">Pod 1</Link> /
    <Link to="/pod/p-0000-0002">Pod 2</Link> /
    <Link to="/pod/p-0000-0003">Pod 3</Link> /
    <Link to="/pod/2KU.1.8.1jUg04">keyphrase-pod-2</Link>
    ...
    <Link to="/pod/1Go.D.F/contents">1FP.1.1</Link>
  </div>
}

export default observer(PodImpro)