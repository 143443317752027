import React from "react"
import { observer } from "mobx-react-lite"

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FileUploader } from "react-drag-drop-files";
import dayjs from "dayjs"

import { useStore } from '../../../contexts/store'

import { PdfImportFileInfo } from '../../../stores/PdfImportStore'
import ImportablePdf from "./ImportablePdf"
import { Op, Op_addComment, Op_addPdfFile } from "../../../../../types/Ops"
import { Interaction, InteractionTypePlural, iComment } from "../../../../../types/Interaction"
import { alert } from "../../../stores/alertStore"
import { env } from "../../../api/api"

const interactionTypePluralLiterals = ['annotations', 'emotions', 'comments', 'links', 'weblinks', 'taggings', 'readingQuestions'] as const


const ImportDialog = ({  }: {}) => {
  const { podStore, sessionStore, pdfImportStore, opStore, uploadStore, alertStore } = useStore()
  const { t } = useTranslation()

  const pod = podStore.pod
  const session = sessionStore.session

  const clear = () => {
    pdfImportStore.clear()
  }

  const doImport = () => {
    var successfulRun = true
    pdfImportStore.queue.forEach((file) => {
      if (file.file && file.doImport && pod) {

        const nodeId = sessionStore.createUuid()
        const userInfo = {
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        }

        const op:Op_addPdfFile = {
          op: 'addPdfFile',
          podId: pod.podId,
          data: {
            nodeId,
            name: file.shrimpData?.node?.name || file.uploadName || 'unknown file',
            description:'',
            folderId:'',
            userId: sessionStore.session.user.userId,
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId || '',
            userName: podStore.userPseudonym || '',
            weight: 100 + Object.keys(pod.content.pdfFiles).reduce((acc, nodeId) => { return Math.max(acc, pod.content.pdfFiles[nodeId].weight) }, 0),
            hidden: true,
            size: file.file.byteLength || 0,
            hash: '',
            nofPages:0,
            status: 'scheduled',
            coid:null,
          }
        }

        const importOps:Op[] = []

        if (file.shrimpData) {
          interactionTypePluralLiterals.forEach((interactionType:InteractionTypePlural) => {
            if (file.shrimpData.node[interactionType]) {
              {
                Object.keys(file.shrimpData.node[interactionType]).map((interactionId:string) => {
                  const interaction:Interaction = file.shrimpData.node[interactionType][interactionId]

                  // update the interaction for a new context
                  interaction.anchor.nodeId = nodeId
                  interaction.interactionId = sessionStore.createUuid()
                  interaction.coid          = null
                  interaction.tCreated      = dayjs().unix()
                  interaction.tModified     = dayjs().unix()

                  switch(interactionType) {
                    case 'comments': const op:Op_addComment = { op:'addComment', podId: pod.podId, data: { ...interaction, ...userInfo } as iComment }; importOps.push(op)
                  }

                })
              }
          }})

          console.log(importOps)
        }

        const success = (nodeId:string, filename:string) => {
          alertStore.push(alert(`Uploaded ${filename}. Applying ${importOps.length} ops`, 'success'))
          opStore.doOp(op)
          importOps.forEach(importOp => opStore.doOp(JSON.parse(JSON.stringify(importOp))))
        }

        const failure = (filename:string) => {
          alertStore.push(alert(`Could not upload ${filename}. Please try again later.`, 'error'))
          successfulRun = false
        }

        uploadStore.add(`${env.backendBaseUrl}/uploadPdf`, pod.podId, { assetId: nodeId }, file.file, 'application/pdf', (id:string) => success(id, file.uploadName), () => failure(file.uploadName))

      }
    })

    if (successfulRun) pdfImportStore.clear()

  }

  const processFileList = (fileList:FileList) => {
    console.log(fileList)
    for (var i=0; i<fileList.length; i++) {
      const file = fileList[i]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          const entry: PdfImportFileInfo = {
            file: fileData,
            uploadName: file.name,
            shrimpData: null,
            status: 'pending',
          }
          pdfImportStore.addToQueue(entry)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const onChangeFile = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return false
    processFileList(e.target.files)
    e.target.value = ''
  }

  /**

  <input id="fileField"
            type="file"
            multiple={true}
            onChange={onChangeFile}
            style={{position:'absolute', left:10}}
          />

   */

  return <Dialog open={Boolean(pdfImportStore.queue.length)}>
    <DialogTitle>Import {pdfImportStore.queue.filter((e) => e.doImport).length} of {pdfImportStore.queue.length} Files</DialogTitle>
    <DialogContent>
      { pdfImportStore.queue.map((entry, i) => <ImportablePdf key={i} i={i} />)}
      <FileUploader multiple hoverTitle={'Drop files here'} handleChange={processFileList} name="file">
        <Typography variant="caption" sx={{width:'100%', borderWidth:'2px', borderStyle:'dashed', borderColor:'primary.main', display:'block', textAlign: 'center', padding: '8px'}}>To add files, drop them here or click</Typography>
      </FileUploader>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={clear}>Cancel</Button>
      <Button variant="contained" onClick={doImport}>Import</Button>
    </DialogActions>
  </Dialog>

}

export default observer(ImportDialog)