import { observer } from "mobx-react-lite"
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from "react-i18next"
import React from "react"

import { useStore } from '../../../contexts/store'

import { PdfImportFileInfo } from '../../../stores/PdfImportStore'
import { humanReadableBytes } from "../../../helper/Helper";
import { Interaction, InteractionTypePlural } from "../../../../../types/Interaction";
const interactionTypePluralLiterals = ['annotations', 'emotions', 'comments', 'links', 'weblinks', 'taggings', 'readingQuestions'] as const

const ImportablePdf = ({ i }: { i:number }) => {
  const { podStore, sessionStore, pdfImportStore } = useStore()
  const file = pdfImportStore.queue[i]
  const { t } = useTranslation()
  const session = sessionStore.session

  if (!file) return null

  const interactionChecklist:React.JSX.Element[] = []

  interactionTypePluralLiterals.forEach((interactionType:InteractionTypePlural) => {
    if (file.shrimpData && file.shrimpData.node && file.shrimpData.node[interactionType]) {
      {
        Object.keys(file.shrimpData.node[interactionType]).map((interactionId:string) => {
          const interaction:Interaction = file.shrimpData.node[interactionType][interactionId]
          interactionChecklist.push(<li key={interactionId}>
            {interaction.interactionType} by {interaction.userName}
          </li>)
        })
      }
  }})

  var hash:React.JSX.Element|null = file.shrimpData?.node ? <span>{file.shrimpData.node.hash}</span> : null
/**
  <CardHeader
    title={<>Import "{file.uploadName}" as "{file.shrimpData?.node?.name}"</>}
    subheader={<>(<>{`${interactionChecklist.length} SHRIMP Interactions, ${humanReadableBytes(file.file?.byteLength || 0)} of PDF data, `}</>{hash})</>}
  />

 */
  var info = null
  if (!file.file) info = <Box>This does not seem to be a PDF file.</Box>
  if (file.file) info = <Typography>{`${humanReadableBytes(file.file?.byteLength || 0)}`}</Typography>

  const interactionSelection = interactionChecklist.length ?
    <ol style={{maxHeight:110, overflow:'auto', margin:0, backgroundColor:'#f8f8f8', border:'1px solid #ccc'}}>{interactionChecklist}</ol>
    : null

  const lineLead = (file.status === 'parsed') ?
    <Checkbox disabled={!Boolean(file.file)} checked={Boolean(file.doImport)} onChange={() => { pdfImportStore.queueModDoImport(i, !Boolean(file.doImport)) }} sx={{paddingTop:'4px'}} />
    : <CircularProgress size={24} sx={{marginLeft:'9px', marginRight: '9px'}} />

  return <Card sx={{ marginBottom:1, border: '1px solid #aaa', opacity: (Boolean(file.file) && (file.status === 'parsed')) ? 1 : .5 }}>
  <CardContent>
    <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-start", alignItems: "flex-start", }}>
      {lineLead}
      <Box>
        <Typography variant="h6"> {file.uploadName}{file.shrimpData?.node?.name && ` as "${file.shrimpData?.node?.name}"`}</Typography>
        {info}
        {interactionSelection}
      </Box>
    </Stack>
  </CardContent>
</Card>

}

export default observer(ImportablePdf)