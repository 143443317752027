import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from "../../../stores/uiStore"
import { useStore } from "../../../contexts/store"
import { iLink, InteractionType, iReadingQuestion, iTag, iWeblink } from '../../../../../types/Interaction'
import BlockquoteBox from '../../Elements/BlockquoteBox'
import EditMenu from '../../Elements/EditMenu'

import {Box, Drawer, IconButton} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowForwardIos'
import Thread from '../../Elements/Thread'
import Note from '../../Elements/Note'
import FollowLink from '../../Elements/FollowLink'
import Tagging from '../../Elements/Tagging'
import Weblink from '../../Elements/Weblink'
import { useSwipeable } from 'react-swipeable'
import { navigate } from 'wouter/use-location'
import ReadingQuestion from '../../Elements/ReadingQuestion'

const InteractionDrawer = ({storeId, pageListRef}: {storeId: string, pageListRef: any}) => {
  let isOpen = false
  const { uiStore, podStore } = useStore()
  const { podId, pdfId, interactionId } = useParams()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`

  // the display of the drawer is controlled by the route, navigating to the pdf closes the drawer
  const handleClose = () => {
    uiStore.setEditedMessageId(null)
    navigate(`${pdfUrl}`, {replace: true})
  }

  const handlers = useSwipeable({
    onSwipedRight: handleClose,
  })


  const pod = podStore.pod
  if (!pod) return null

  const interaction = interactionId ? pod.getInteraction(interactionId) : null
  if(!interaction) return null

  // for tags, use the sidebar instead of the drawer
  if(interaction.interactionType === "tagging") return null

  // drawer opens only if the user is on the route of an interaction
  if(interaction && interactionId) isOpen = true

  // drawer adapts to the width of the screen and height of the header
  const drawerWidth = 500
  const width = uiStore.width
  let headerHeight = document.getElementById("app-header")?.offsetHeight
  if(!headerHeight) headerHeight = 64

  const Header = observer(() => {
    if (!interaction) return null
    // interaction color
    let color = uiStore.getInteractionColor(interaction.interactionType as InteractionType)
    if(interaction.interactionType === 'annotation' && interaction.style.color) color = interaction.style.color
    return <Box sx={{
      alignContent: "center",
      backgroundColor: color,
      display: "grid",
      gridTemplateColumns: "min-content auto",
      justifyItems: "end",
      height: "60px",
      padding: "0 20px",
      position: "sticky",
      top: 0,
      zIndex: 5
    }}
    >
      <IconButton aria-label="back" onClick={handleClose}>
        <ArrowBackIcon sx={{color: "white"}} />
      </IconButton>
      <EditMenu
        iconColor="white"
        interactionId={interaction.interactionId}
        interactionType={interaction.interactionType}
        pdfId={pdfId}
        podId={podId}
      />
    </Box>
  })

  const Content = () => {
    if (!interaction) return null
    const interactionType = interaction.interactionType
    if(interactionType === "annotation") return <Note backUrl={pdfUrl} interaction={interaction} podId={podId} />
    if(interactionType === "comment") return <Thread interaction={interaction} podId={podId} />
    if(interactionType === "emotion") return <Box><BlockquoteBox interaction={interaction} /></Box>
    if(interactionType === "link") return <FollowLink link={interaction as iLink} pdfId={pdfId} podId={podId} />
    if(interactionType === "readingQuestion") return <ReadingQuestion readingQuestion={interaction as iReadingQuestion} />
    if(interactionType === "tagging") return <Tagging  pdfId={pdfId} podId={podId} tag={interaction as iTag} />
    if(interactionType === "weblink") return <Weblink weblink={interaction as iWeblink} />
    return null
  }

  const Container = () => {
    if (!interaction) return null
    return <Box
      sx={{
        display: "grid",
        height: "100%",
        gridTemplateRows: "min-content auto",
        width: drawerWidth < width ? drawerWidth : width,
      }}
      role="presentation"
    >
      <Header />
      <Content />
    </Box>
  }

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={handleClose}
      {...handlers}
      transitionDuration={{ appear: 100, enter: 100, exit: 100 }}
      sx={{
        marginTop: `${headerHeight}px`,
        '&.MuiDrawer-root .MuiModal-backdrop': { position: "absolute" },
        '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: `${headerHeight}px`, height: `calc(100% - ${headerHeight}px)` },
      }}
    >
      <Container />
    </Drawer>
  )
}

export default observer(InteractionDrawer)