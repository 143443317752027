import { useTranslation } from "react-i18next"
import { Link } from 'wouter'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { FormControl, InputLabel, MenuItem, Link as MuiLink, Select, SelectChangeEvent } from "@mui/material"

import React, { ReactElement, ReactNode, useEffect } from "react"


import { useParams } from "../../stores/uiStore"
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'


type Tab = {
  key: string,
  label: string|ReactNode,
  content: ReactElement,
}

const activeTabStyle = {
  alignContent: "center",
  backgroundColor: "white",
  /*borderTop: "2px solid rgb(108, 108, 211)",
  borderLeft: "2px solid rgb(108, 108, 211)",
  borderRight: "2px solid rgb(108, 108, 211)",
  borderBottom: "2px solid white",*/
  borderBottom: "2px solid rgb(108, 108, 211)",
  color: "rgb(108, 108, 211)",
  display: "grid",
  fontSize: "12px",
  fontWeight: "500",
  gridTemplateColumns: "min-content min-content",
  gridGap: "5px",
  padding: "8px"
}

const tabStyle = {
  alignContent: "center",
  //borderTop: "2px solid white",
  //borderBottom: "2px solid #888",
  color: "rgba(0, 0, 0, 0.6)",
  display: "grid",
  fontSize: "12px",
  fontWeight: "500",
  gridTemplateColumns: "min-content min-content",
  gridGap: "5px",
  padding: "8px",
  //backgroundColor:'#eee',
}

// message from thread
const Subtabs = ({context, activeKey, tabs}: {context:string, activeKey:string|undefined, tabs:Tab[]}) => {

  const { podStore, uiStore } = useStore()
  const deviceWidth = uiStore.deviceWidth
  const pod = podStore.pod
  if (!pod) return null
  if (tabs.length === 0) return null

  if (!activeKey) activeKey = tabs[0].key

  function generateUrl(key: string) {
    if (!pod) return ''
    const url = `/pod/${pod.podId}/${context}/${key}`
    return url
  }

  const Menu = () => {
    // show selection menu on small screens
    // if(deviceWidth === "tiny" || deviceWidth === 'smaller') return <SeclectionMenu />
    // else show tab menu

    return (
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2px",
        margin: "0 10px 20px 10px",
        alignItems:'start',
      }}>
        {
        tabs.map((tab, i) => (<Link key={tab.key} to={generateUrl(tab.key)}>
          <MuiLink underline="none" sx={ activeKey === tab.key ? activeTabStyle : tabStyle } style={{textTransform: 'uppercase'}}>
            {tab.label}
          </MuiLink>
        </Link>))
        }
      </Box>
    )
  }

  const content = tabs.filter(tab => tab.key === activeKey)[0].content || tabs[0].content

  return <Box className={"overflowWrapper"} style={{ gridTemplateRows: 'min-content auto' }}>
    <Menu />
    <Box className={"overflowWrapper"}>
    { content }
    </Box>
  </Box>

}

export default observer(Subtabs)