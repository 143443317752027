import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { HexColorPicker } from "react-colorful";
import { UserInfo } from "../../../../types/User"
import { t } from "i18next";
import { useStore } from "../../contexts/store";
import { usernameMaxLength } from "../../validationConstantsString";
import Resizer from "react-image-file-resizer";
import { Op_editPod, Op_editUserInfo } from "../../../../types/Ops";
import { env } from '../../api/api'
import { ImageUploadbutton } from "../Elements/ImageUploadButton";

const EditUserInfo = ({userInfo, handleClose, title}:{ userInfo:UserInfo|null, handleClose:any, title?:string }) => {
  const [ userName, setUserName ] = React.useState<string|null>(userInfo ? userInfo.userName : null)
  const [ color, setColor ] = React.useState<string|null>(userInfo && userInfo.color ? userInfo.color : null)
  const [ cPos, setCPos] = React.useState<number>(0)
  const { uiStore, podStore, sessionStore, uploadStore, opStore } = useStore()
  const input = React.useRef(HTMLInputElement)

  const session = sessionStore.session

  useEffect(() => {
    if (userName === null && userInfo) {
      setUserName(uiStore.restrictString(userInfo.userName, 'username'))
    }
    if (!color && userInfo && userInfo.color) setColor(userInfo.color)
    if (typeof input.current !== 'function') {
      const elm = input.current as HTMLInputElement
      if (elm) {
        elm.setSelectionRange(cPos, cPos)
        elm.focus()
      }
    }
  }, [userName, userInfo, color, cPos])

  let open = false
  if (userInfo) {
    open = Boolean(userInfo) && Boolean(userInfo.userId)
  }

  const exit = () => {
    setUserName(null)
    setCPos(0)
  }

    /*
  const resizeFile = (file:File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        480,
        480,//200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob'
      )
    })

  const uploadAvatar = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return false
    try {
      const file = e.target.files[0]
      const image = await resizeFile(file)
      const imageBlob = image as Blob
      const imageArrayBuffer = await imageBlob.arrayBuffer()
      const fileId = sessionStore.createUuid()
      const pod = podStore.pod
      if (!pod) return false

      const op:Op_editUserInfo = {
        op: 'editUserInfo',
        podId: pod.podId,
        data: {
          mods: { avatarFileId: fileId },
          userId: session.user.userId,
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userName: podStore.userPseudonym || '',
        }
      }

      uploadStore.add(`${env.backendBaseUrl}/uploadFile`, pod.podId, {
        fileId: fileId,
        type: 'avatar',
        rel: session.user.userId,
        size: imageArrayBuffer.byteLength,
      }, imageArrayBuffer, 'application/jpg', () => { opStore.doOp(op) }, () => { console.log('failure') })

      e.target.value = ''
    } catch (err) {
      console.log(err)
    }
  }
  */

  var op:Op_editUserInfo|null = null
  const pod = podStore.pod
  if (pod) {
    op = {
      op: 'editUserInfo',
      podId: pod.podId,
      data: {
        mods: {  },
        userId: session.user.userId,
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userName: podStore.userPseudonym || '',
      }
    }
  }

  const avatarUploadSuccess = (fileId:string) => {
    if (op) {
      op.data.mods.avatarFileId = fileId
      console.log(op)
      opStore.doOp(op)
    }
  }

  const avatarUploadFailure = () => {
    console.log('failure')
  }

  return  <Dialog open={ open }>
      <DialogTitle>{title ? title : t('Set Userinfo')}</DialogTitle>
      <DialogContent>
        <TextField
          label='Username in this Pod'
          value={ userName || '' }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement
            setUserName(uiStore.restrictString(event.target.value, 'username'))
            setCPos(target.selectionStart || 0)
          }}
          style={{ margin:'8px 0'}}
          inputProps={{ ref:input, maxLength: usernameMaxLength }}
          />
        <HexColorPicker color={color ? color : 'aaa'} onChange={setColor} />
        {op ? <ImageUploadbutton fileId={podStore.userInfo?.avatarFileId || null} type='avatar' success={avatarUploadSuccess} failure={avatarUploadFailure} width={480} height={480} sx={{marginTop:2}} /> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { exit(); handleClose()}}>{t('Cancel')}</Button>
        <Button disabled={userName === null || userName.trim().length === 0} variant='contained' autoFocus onClick={() => {
          const userId = userInfo?.userId || 0
          exit();
          if (userId && userName) handleClose({
            userId,
            color,
            userName,
          })
          }}
          >
          {t('Ok')}
        </Button>
      </DialogActions>
  </Dialog>

}

export default observer(EditUserInfo)