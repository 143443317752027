import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import exportSessionStore from './sessionStore'
import api from '../api/api'
import { env } from '../api/api'
import {
  PDFDocument,
  PDFName,
  PDFDict,
  PDFArray,
  PDFStream,
  decodePDFRawStream,
  PDFHexString,
  PDFString,
  PDFRawStream,
} from 'pdf-lib'


export interface pdfImportStoreModel {
  queue:PdfImportFileInfo[],
  addToQueue: (file:PdfImportFileInfo) => void,
  clear: () => void,
  queueModDoImport: (i:number, doImport:boolean) => void,
  queueModSkipInteractions: (i:number, skipInteractions: object) => void,
}

export type PdfImportFileInfo = {
  file:ArrayBuffer|null,
  uploadName:string,
  shrimpData: any,
  status: 'pending'|'processing'|'parsed',
  doImport?: undefined|boolean,
  skipInteractions?: undefined|Object,
}


function extractRawAttachments (pdfDoc: PDFDocument) {
  if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
  const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

  if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
  const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

  if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
  const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

  const rawAttachments = [];
  for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
    const fileName = EFNames.lookup(idx) as PDFHexString | PDFString;
    const fileSpec = EFNames.lookup(idx + 1, PDFDict);
    rawAttachments.push({ fileName, fileSpec });
  }

  return rawAttachments;
}

function getShrimpData(pdfDoc: PDFDocument) {
  const rawAttachments = extractRawAttachments(pdfDoc);
  const shrimpAttachments = rawAttachments
    .map(({ fileName, fileSpec }) => {
      const stream = fileSpec
        .lookup(PDFName.of('EF'), PDFDict)
        .lookup(PDFName.of('F'), PDFStream) as PDFRawStream
      return {
        name: fileName.decodeText(),
        data: decodePDFRawStream(stream).decode(),
      };
    })
    .filter(attachment => attachment.name === 'Shrimp')
    if (shrimpAttachments.length === 1) {
      const string = new TextDecoder().decode(shrimpAttachments[0].data)
      return JSON.parse(string)
    }
    else {
      console.warn(`Found ${shrimpAttachments.length} Shrimp attachments`)
      return null
    }
}

const processPdf = async (buffer:ArrayBuffer|null) => {
  if (!buffer) return {
    file:null,
    shrimpData:null,
  }

  try {
    const pdfDoc = await PDFDocument.load(buffer)

    const shrimpData = getShrimpData(pdfDoc)

    // Strip all embeddings
    if (pdfDoc.catalog.has(PDFName.of('Names'))) {
      const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);
      if (Names.has(PDFName.of('EmbeddedFiles'))) {
        Names.delete(PDFName.of('EmbeddedFiles'))
      }
    }

    return {
      file: await pdfDoc.save(),
      shrimpData
    }
  }
  catch(e) {
    console.error(e)
    return {
      file:null,
      shrimpData:null,
    }
  }
}


class uploadStore {

  queue:PdfImportFileInfo[] = []
  status: 'idle'|'busy' = 'idle'

  constructor() {
    makeObservable(this, {
      queue: observable,

      addToQueue: action,
      shiftFromQueue: action,
      clear: action,
      setStatus: action,
      processQueue: action,
      queueModStatus: action,
      queueModFile: action,
      queueModShrimpData: action,
      queueModDoImport: action,
      queueModSkipInteractions: action,
    })
  }

  setStatus(status: 'idle'|'busy') {
    this.status = status
  }

  async processQueue() {
    while(this.queue[this.queue.length-1].status === 'pending') {
      this.setStatus('busy')
      const firstUnprocessed = this.queue.findIndex(r => r.status === 'pending')
      console.log(`Process #${firstUnprocessed}`)
      this.queueModStatus(firstUnprocessed, 'processing')
      const { file, shrimpData } = await processPdf(this.queue[firstUnprocessed].file)
      if (file) {
        this.queueModFile(firstUnprocessed, file)
        this.queueModShrimpData(firstUnprocessed, shrimpData)
        this.queueModDoImport(firstUnprocessed, true)
      }
      else {
        this.queueModFile(firstUnprocessed, null)
        this.queueModShrimpData(firstUnprocessed, null)
        this.queueModDoImport(firstUnprocessed, false)
      }
      console.log('Done processing')
      this.queueModStatus(firstUnprocessed, 'parsed')
      this.setStatus('idle')
    }
  }

  addToQueue(file:PdfImportFileInfo) {
    this.queue.push(file)
    if (this.status === 'idle') this.processQueue()
  }

  shiftFromQueue() {
    return this.queue.shift()
  }

  queueModStatus(i:number, status:'pending'|'processing'|'parsed') {
    this.queue[i].status = status
  }

  queueModFile(i:number, file:ArrayBuffer|null) {
    this.queue[i].file = file
  }

  queueModShrimpData(i:number, shrimpData:any) {
    this.queue[i].shrimpData = shrimpData
  }

  queueModDoImport(i:number, doImport:boolean) {
    this.queue[i].doImport = doImport
  }

  queueModSkipInteractions(i:number, skipInteractions:object) {
    this.queue[i].skipInteractions = skipInteractions
  }

  clear() {
    this.queue.length = 0
  }

}

const exportUploadStore = new uploadStore()
export default exportUploadStore