import { observer } from "mobx-react-lite"
import { navigate } from "wouter/use-location"
import { useStore } from "../../../contexts/store"
import { useParams } from "../../../stores/uiStore"
import CommentSidebar from "./CommentSidebar"
import LinkSidebar from "./LinkSidebar"
import TagSidebar from "./TagSidebar"
import SearchSidebar from "./SearchSidebar"

import { Box, IconButton } from "@mui/material"

import SearchIcon from '@mui/icons-material/Search';
import AnnotationIcon from '@mui/icons-material/FormatColorText'
import CommentIcon from '@mui/icons-material/Comment'
import LinkIcon from '@mui/icons-material/Link'
import TagIcon from '@mui/icons-material/Sell'
import WeblinkIcon from '@mui/icons-material/OpenInNew'
import EmotionIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import AddReactionIcon from '@mui/icons-material/AddReactionOutlined'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ReadingQuestionIcon from '@mui/icons-material/ContactSupport'


const Sidebar = ({pageListRef}: {pageListRef: any}) => {
  const { uiStore } = useStore()
  const { podId, pdfId } = useParams()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`

  // hide sidebar on smartphone
  if(uiStore.deviceWidth === "tiny") return null

  const handleSidebarStatus = (sidebarStatus: string) => {
    // tidy up previous tag selection
    if(uiStore.sidebarStatus === 'tag') {
      uiStore.clearActiveSidebarTags()
      navigate(`${pdfUrl}`, {replace: true})
    }
    // change sidebar status
    if(uiStore.sidebarStatus === sidebarStatus) {
      uiStore.setSidebarStatus('closed')
    }
    else uiStore.setSidebarStatus(sidebarStatus)
  }

  return (
  <Box sx={{
    backgroundColor: "ghostwhite",
    display: "grid",
    gridTemplateColumns: "auto max-content",
    overflowY: "auto"
  }}>
    {uiStore.sidebarStatus === 'search' && <SearchSidebar />}
    {uiStore.sidebarStatus === 'comment' && <CommentSidebar />}
    {uiStore.sidebarStatus === 'link' && <LinkSidebar />}
    {uiStore.sidebarStatus === 'tag' && <TagSidebar pageListRef={pageListRef} />}
    <Box sx={{
      alignContent: "center",
      display: "grid",
      overflowY: "auto",
      padding: "5px"
    }}>
      <IconButton onClick={() => handleSidebarStatus('search')} sx={{backgroundColor: uiStore.sidebarStatus === "search" ? "white" : "white", borderRadius: "5px", margin: "3px 2px", "&:hover": {backgroundColor: "#f1f1f1"}, outline: uiStore.sidebarStatus === "search" ? "2px solid lightgrey" : ""}} size="small" aria-label="open-search-sidebar">
        <SearchIcon sx={{color: uiStore.sidebarStatus === "search" ? "black" : "grey"}} fontSize="small" />
      </IconButton>
      <IconButton onClick={() => handleSidebarStatus('comment')} sx={{backgroundColor: uiStore.sidebarStatus === "comment" ? "white" : "#ffd800", borderRadius: "5px", margin: "3px 2px", "&:hover": {backgroundColor: "#ffd80069"}, outline: uiStore.sidebarStatus === "comment" ? "2px solid #ffd80069" : ""}} size="small" aria-label="open-comment-sidebar">
        <CommentIcon sx={{color: uiStore.sidebarStatus === "comment" ? "#ffd800" : "white"}} fontSize="small" />
      </IconButton>
      <IconButton onClick={() => handleSidebarStatus('link')} sx={{backgroundColor: uiStore.sidebarStatus === "link" ? "white" : "#0b84fa", borderRadius: "5px", margin: "3px 2px", "&:hover": {backgroundColor: "#0b84fa75"}, outline: uiStore.sidebarStatus === "link" ? "2px solid #0b84fa" : ""}} size="small" aria-label="open-link-sidebar">
        <LinkIcon sx={{color: uiStore.sidebarStatus === "link" ? "#0b84fa" : "white"}} fontSize="small" />
      </IconButton>
      <IconButton onClick={() => handleSidebarStatus('tag')} sx={{backgroundColor: uiStore.sidebarStatus === "tag" ? "white" : "#7400bf", borderRadius: "5px", margin: "3px 2px", "&:hover": {backgroundColor: "#7400bf6e"}, outline: uiStore.sidebarStatus === "tag" ? "2px solid #7400bf6e" : ""}} size="small" aria-label="open-tag-sidebar">
        <TagIcon sx={{color: uiStore.sidebarStatus === "tag" ? "#7400bf" : "white"}} fontSize="small" />
      </IconButton>
    </Box>
  </Box>
)
}

export default observer(Sidebar)