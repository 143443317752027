import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import exportSessionStore from './sessionStore'
import api from '../api/api'
import { env } from '../api/api'

const timeout = 1000 * 60 * 15

export interface uploadStoreModel {
  uploadQueue:UploadJob[],
  add: (endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, mimeType:string, successCallback:Function|null, failureCallback:Function|null) => void,
  status: 'idle'|'uploading'|'error',
}

export type UploadJob = {
  file: ArrayBuffer,
  mimeType:string,
  signedUrl: string,
  successCallback: Function|null,
  failureCallback: Function|null,
}

class uploadStore {

  uploadQueue:UploadJob[] = []
  status:'idle'|'uploading'|'error' = 'idle'

  constructor() {
    makeObservable(this, {
      uploadQueue: observable,
      status: observable,

      addToQueue: action,
      shiftFromQueue: action,
      setStatus: action,
    })
  }

  setStatus(status:'idle'|'uploading'|'error') {
    console.log(`set uploadStore.status = ${status}`)
    this.status = status
  }

  addToQueue(job:UploadJob) {
    this.uploadQueue.push(job)
  }

  shiftFromQueue() {
    return this.uploadQueue.shift()
  }

  async doUpload() {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(`timeout of ${timeout/1000}s`), timeout)
    //if (this.uploadQueue.length > 0) return

    while(this.uploadQueue.length) {
      const entry = this.shiftFromQueue()
      if (!entry) continue

      console.log('do upload', entry)

      this.setStatus('uploading')
      try {
        const fileUploadQuery = await fetch(entry.signedUrl, {
          method: "PUT",
          mode: "cors",
          signal: controller.signal,
          headers: {
            'Content-Type': entry.mimeType,
          },
          body: entry.file
        })
        this.setStatus('idle')
        if ((fileUploadQuery.status === 200) && entry.successCallback) entry.successCallback()
        if ((fileUploadQuery.status !== 200) && entry.failureCallback) entry.failureCallback()
      }
      catch (e) {
        this.setStatus('idle')
        console.error(e)
        if (entry.failureCallback) entry.failureCallback()
      }
      finally {
        clearTimeout(id)
        this.setStatus('idle')
      }
    }
  }

  async add(endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, mimeType:string, successCallback:Function|null = null, failureCallback:Function|null=null) {

    const sessionId = exportSessionStore.session ? exportSessionStore.session.sessionId : null
    const res = await api.fetch(endpoint, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        podId,
        mimeType,
        ...signedUrlAttributes,
      })
    })

    const { signedUrl } = res.body ? res.body : { signedUrl: null }

    if (signedUrl) {
      this.addToQueue({
        file,
        mimeType,
        signedUrl,
        successCallback,
        failureCallback
      })
      if (this.status !== 'uploading') this.doUpload()
    }
    else {
      if (failureCallback) failureCallback()
    }
  }

}

const exportUploadStore = new uploadStore()
export default exportUploadStore