import { Box } from "@mui/material"

const LinkSidebar = () => {
  return (
    <Box sx={{width: "250px"}}>
      <Box>
        Link Sidebar
      </Box>
      Cooming soon ...
    </Box>
  )
}

export default LinkSidebar