import { t } from "i18next"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { PodClass } from "../../classes/Pod"

import { useStore } from '../../contexts/store'
import { useParams } from "../../helper/Helper"
import { Op_editUserInfo, Op_removeUserFromPod } from "../../../../types/Ops"
import { UserInfo } from "../../../../types/User"

import { Box, Button } from '@mui/material'
import { IconButton } from '@mui/material'
import PasswordIcon from '@mui/icons-material/Key'
import LogoutIcon from '@mui/icons-material/Logout'
import EditIcon from '@mui/icons-material/Edit'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'

import UserInfoAvatar from "../Elements/UserInfoAvatar"
import EditUserInfo from "./EditUserInfo"
import AddUserModal from "./AddUser/AddUserModal"
import ChangePasswordModal from "./ChangePasswordModal"
import ConfirmDialog from "../Elements/ConfirmDialog"
import AddUserButton from "./AddUser/AddUserButton"


const PodUsers = ({initScrollPos}: {initScrollPos: (tab: string) => void}) => {
  const { podId } = useParams()
  const { podStore, opStore, uiStore, sessionStore } = useStore()
  const [editUserInfo, setEditUserInfo] = React.useState<UserInfo|null>(null)
  const [changeUserPassword, setChangeUserPassword] = useState<UserInfo | null>(null)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<UserInfo | null>(null)

  useEffect(() => {
    return () => {
      // when unmounting people: clear pad, instant user list and close modal
      uiStore.updateInstantUserPad('')
      uiStore.deleteInstantUserList()
      uiStore.closeAddUserModal()
    }
  }, [])

  useLayoutEffect(() => {
    initScrollPos("people")
  })

  const pod:PodClass|null = podStore.pod
  if (!pod) return null
  // check permissions
  const addUserToPodPermission = pod.permissions['addUserToPod'] ? true : false
  // list of pod user
  const podUsers = Object.keys(pod.userInfos).map((userId:string) => pod.userInfos[parseInt(userId)])
  if(!podUsers) return null

  const removeUserFromPod = (userId:number) => {
    if (pod.userInfos) {
      const op:Op_removeUserFromPod = {
        op: 'removeUserFromPod',
        podId: pod.podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId,
          userName: podStore.userPseudonym ? podStore.userPseudonym : '',
        }
      }
      opStore.doOp(op)
    }
  }

  const changeUserInfo = (userInfo: UserInfo) => {
    if (pod.userInfos) {
      const op:Op_editUserInfo = {
        op: 'editUserInfo',
        podId: pod.podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId: userInfo.userId,
          userName: podStore.userPseudonym || '',
          mods: {
            color: userInfo.color,
            userName: userInfo.userName,
            // avatarAssetId: userInfo.avatarAssetId,
          }
        }
      } as Op_editUserInfo
      opStore.doOp(op)
    }
  }

  const handleDelete = () => {
    if(confirmDeleteOpen) {
      //api.deleteUser(podId, confirmDeleteOpen.userId)
      removeUserFromPod(confirmDeleteOpen.userId)
      setConfirmDeleteOpen(null)
    }
  }

  const handleClose = (values:UserInfo|null) => {
    if (values) {
      const userName = values?.userName?.trim()
      const color = values?.color
      if (userName && color && editUserInfo && editUserInfo.userId) changeUserInfo(values)
    }
    setEditUserInfo(null)
  }

  const PodUser = observer(({user}: {user: UserInfo}) => {
    const activeUserId = sessionStore.session.user.userId
    const changeUserPasswordIsAllowed = user.patronUserId === activeUserId && activeUserId !== user.userId
    const deleteUserIsAllowed = user.patronUserId === activeUserId && activeUserId !== user.userId
    const editIsAllowed = pod.isAllowed('editUserInfo', user.userId) ? true : false
    // instant users (login is not null) are not allowed to leave the pod independently
    const leavingIsAllowed = (pod.isAllowed('removeUserFromPod', user.userId) && user.login === null) ? true : false

    return (
      <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", alignContent: "start", gridGap: "15px", marginBottom: "10px", '&:hover':{ backgroundColor: "#fafafa"} }}>
        <UserInfoAvatar userId={user.userId} podId={podId} />
        <Box sx={{wordBreak: "break-all"}}>
          {user.userName}
        </Box>
        <Box sx={{display: "grid", gridTemplateColumns: "min-content min-content min-content", marginLeft: "20px"}}>
          {editIsAllowed &&
            <IconButton sx={{ml: "20px"}} title={'Change your name and avatar color'} onClick={() => setEditUserInfo({
              userId: user.userId,
              login: user.login,
              userName: user.userName,
              color: user.color,
              // avatarAssetId: user.avatarAssetId,
              })}>
              <EditIcon />
            </IconButton>
          }
          {changeUserPasswordIsAllowed &&
            <IconButton title={t('changeUserPassword') as string} onClick={() => setChangeUserPassword(user)}>
              <PasswordIcon />
            </IconButton>
          }
          {deleteUserIsAllowed &&
            <IconButton title={t('removeUserFromPod') as string} onClick={() => setConfirmDeleteOpen(user)}>
              <PersonRemoveIcon />
            </IconButton>
          }
          {leavingIsAllowed &&
            <IconButton title={'Leave this pod'} onClick={() => removeUserFromPod(user.userId)}>
              <LogoutIcon />
            </IconButton>
          }
        </Box>
      </Box>
    )
  })

  return (
    <Box className="overflowWrapper" sx={{alignContent: "start"}}>
      <Box className="overflowWrapper">
        {podUsers.map(user =>
          <PodUser key={user.userId} user={user} />
        )}
      </Box>
      {addUserToPodPermission &&
        <AddUserButton />
      }
      <AddUserModal />
      <ChangePasswordModal user={changeUserPassword} handleClose={() => {setChangeUserPassword(null)}} />
      <EditUserInfo userInfo={editUserInfo} handleClose={handleClose} />
      <ConfirmDialog title={t('Warning')} info={t('Do you really want to delete the user {{username}}?', {username: confirmDeleteOpen?.userName}) as string} open={confirmDeleteOpen ? true: false} handleClose={() => { setConfirmDeleteOpen(null) }} onOk={handleDelete} />
    </Box>
  )

}

export default observer(PodUsers)