import { Box } from "@mui/material"

const SearchSidebar = () => {
  return (
    <Box sx={{width: "250px"}}>
      <Box>
        Search Sidebar
      </Box>
      Cooming soon ...
    </Box>
  )
}

export default SearchSidebar