import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { navigate } from 'wouter/use-location'
import { PodClass } from "../../../classes/Pod"
import { useParams } from "../../../stores/uiStore"
import { iTag } from "../../../../../types/Interaction"
import { ChangeEvent, useState } from "react"

import { Box, Button, Chip, FormControlLabel, IconButton, InputBase, Switch } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import FilterIcon from '@mui/icons-material/FilterAltOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowForwardIos'
import EditMenu from "../../Elements/EditMenu"


const TagSidebar = ({pageListRef}: {pageListRef: any}) => {
  const { podStore, uiStore } = useStore()
  const { podId, pdfId, interactionId } = useParams()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  const pod:PodClass|null = podStore.pod
  const [filter, setFilter] = useState("")
  const [showWordCloud, setShowWordCloud] = useState(false)
  const activeTags = uiStore.activeSidebarTags
  if (!pod) return null

  // calculate the font size of the tag label based on its occurrence in the pdf
  let maxTaggingItems = 1
  const minWordSize = 12
  const maxWordSize = 35
  const calculateWordSize = (nofTaggings: number) => {
    if(maxTaggingItems === 1) return 15
    const wordSize = maxWordSize/maxTaggingItems * nofTaggings
    if(wordSize < minWordSize) return minWordSize
    return wordSize
  }

  const taggings = pod.getTaggings(pdfId)
  if(!taggings) return <Box>no tags yet</Box>

  const uniqueTags: {[tagId: string]: iTag[]} = {}
  for(const tag of taggings) {
    if(uniqueTags[tag.tagId]) {
      uniqueTags[tag.tagId].push(tag)
      // get maximal number of tagging items
      const nofTaggingItems = uniqueTags[tag.tagId].length
      if(nofTaggingItems > maxTaggingItems) maxTaggingItems = nofTaggingItems
    } else {
      uniqueTags[tag.tagId] = [tag]
    }
  }

  // activate tag if interaction is selected
  const interaction = interactionId ? pod.getInteraction(interactionId) as iTag : null
  if(interaction && !activeTags.includes(interaction.tagId)) {
    uiStore.toggleActiveSidebarTag(interaction.tagId)
  }

  const toggleTagging = (tagging: iTag) => {
    // select or deselect tagging depending on the previous status
    if(interactionId === tagging.interactionId) navigate(`${pdfUrl}`, {replace: true})
    else {
      // activate tagging
      navigate(`${pdfUrl}/tagging/${tagging.interactionId}`, {replace: true})
      // scroll to tagging
      pageListRef.current.scrollToItem(tagging.anchor.rects[0].p-1, "start")
    }
  }

  const clearAll = () => {
    uiStore.clearActiveSidebarTags()
    setFilter("")
    navigate(`${pdfUrl}`, {replace: true})
  }

  const closeTag = (tagId: string) => {
    if(interaction && interaction.tagId === tagId) navigate(`${pdfUrl}`, {replace: true})
    uiStore.toggleActiveSidebarTag(tagId)
  }

  const closeSidebar = () => {
    uiStore.setSidebarStatus("closed")
    clearAll()
  }

  const PassiveTag = observer(({tagId, taggingList}: {tagId: string, taggingList: iTag[]}) => {
    // only show tag if there is a tag property
    const tagProp = podStore.getTagProp(tagId)
    if(!tagProp) return null
    const wordSize = showWordCloud ? calculateWordSize(taggingList.length) : 14
    // test if tag was filtered
    if(filter && !tagProp.name.toLocaleLowerCase().includes(filter.toLowerCase())) return null
    return (
      <Box sx={{overflowY: "auto", overflowX: "hidden", wordBreak: "break-word", paddingRight: "5px"}}>
        <Chip
          color="secondary"
          label={<Box sx={{wordBreak: "break-all"}}>{`# ${tagProp.name}`}</Box>}
          onClick={() => {uiStore.toggleActiveSidebarTag(tagId)}}
          sx={{
            backgroundColor: "white",
            fontSize: `${wordSize}px`,
            fontWeight: 500,
            margin: "2px",
            padding: `${wordSize-5}px 0`,
          }}
          variant={"outlined"}
        />
      </Box>
    )
  })

  const ActiveTag = observer(({tagId, taggingList}: {tagId: string, taggingList: iTag[]}) => {
    // only show tag if there is a tag property
    const tagProp = podStore.getTagProp(tagId)
    if(!tagProp) return null
    const wordSize = showWordCloud ? calculateWordSize(taggingList.length) : 14
    // test if tag was filtered
    if(filter && !tagProp.name.toLocaleLowerCase().includes(filter.toLowerCase())) return null
    return (
      <Box sx={{
        backgroundColor: "rgb(156, 39, 176)",
        borderRadius: "5px",
        color: "white",
        margin: "5px",
        padding: "5px",
        width: "100%"
      }}>
        <Box sx={{alignItems: "center", display: "grid", fontSize: "17px", gridTemplateColumns: "auto max-content", margin: "0 5px"}}>
          <Box sx={{fontSize: `${wordSize}px`, padding: "2px", wordBreak: "break-all"}}>
            {`# ${tagProp.name}`}
          </Box>
          <IconButton size="small">
            <CloseIcon fontSize="small" onClick={() => closeTag(tagId)} sx={{color: "white"}} />
          </IconButton>
        </Box>
        <Box>
          {taggingList.map(tagging =>
            <Tagging key={tagging.interactionId} tagging={tagging} />
          )}
        </Box>
      </Box>
    )
  })

  const Tagging = observer(({tagging}: {tagging: iTag}) => {
    const activeTagging = (tagging.interactionId === interactionId) ? true : false
    return (
      <Box onClick={() => toggleTagging(tagging)} sx={{
        alignItems: "center",
        backgroundColor: activeTagging ? "white" : "",
        color: activeTagging ? "#404040" : "white",
        display: "grid",
        fontSize: "15px",
        gridTemplateColumns: "auto max-content",
        margin: "3px 10px",
        padding: "4px 10px",
        "&:hover": {
          cursor: "pointer"
        }
      }}>
        {t('Page')} {tagging.anchor.rects[0].p}
        {activeTagging &&
          <EditMenu
            iconColor="rgb(156, 39, 176)"
            interactionId={tagging.interactionId}
            interactionType={tagging.interactionType}
            pdfId={pdfId}
            podId={podId}
          />
        }
      </Box>
    )
  })


  return (
    <Box sx={{display: "grid", gridTemplateRows: "max-content min-content auto max-content", padding: "5px", width: "250px", overflowY: "auto"}}>
      {/* search bar */}
      <Box sx={{display: "grid", gridTemplateColumns: "auto min-content", alignItems: "center"}}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: "white",
            border: "1px solid lightgrey",
            borderRadius: "5px",
            display: 'flex',
            margin: "10px 5px",
          }}
        >
          <IconButton disabled type="button" sx={{ p: '10px' }} aria-label="filter">
            <FilterIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
            placeholder="Filter tags..."
            inputProps={{ 'aria-label': 'filter tags' }}
            value={filter}
          />
          {filter &&
            <IconButton onClick={() => setFilter("")} type="button" sx={{ p: '10px' }} aria-label="clear">
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        </Box>
        <Box
          onClick={closeSidebar}
          sx={{
            alignItems: "center",
            color: "grey",
            display: "grid",
            height: "46px",
            padding: "5px",
            "&:hover": {backgroundColor: "#efefef", cursor: "pointer"}
          }}>
          <ArrowBackIcon fontSize="small" sx={{color: "secondary"}} />
        </Box>
      </Box>
      {/* word cloud switch */}
      <Box sx={{margin: "-2px 10px 0px 15px"}}>
        <FormControlLabel sx={{color: showWordCloud ? "#595959" : "#8e8e8e"}} label="Word cloud" control={
            <Switch checked={showWordCloud} onChange={() => setShowWordCloud(!showWordCloud)} color="secondary" aria-label="activate-word-cloud" />
          }
        />
      </Box>
      {/* list of tags */}
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        marginTop: "5px",
        overflowY: "auto"
      }}>
        {Object.keys(uniqueTags).map(tagId => activeTags.includes(tagId)
          ? <ActiveTag key={tagId} tagId={tagId} taggingList={uniqueTags[tagId]} />
          : <PassiveTag key={tagId} tagId={tagId} taggingList={uniqueTags[tagId]} />)
        }
      </Box>
      {/* footer */}
      <Button onClick={clearAll} sx={{margin: "5px"}} color="secondary">Clear all</Button>
    </Box>
  )
}

export default observer(TagSidebar)