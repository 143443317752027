import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { Badge, BadgeProps, IconButton, styled } from "@mui/material"
import { Interaction, InteractionType } from "../../../../../types/Interaction"
import { navigate } from "wouter/use-location"
import InteractionIcon from "../../Elements/InteractionIcon"

// shows interaction as marginalia icon
const MarginaliaIcon = (
  {
    item,
    pdfUrl,
    radius
  }: {
    item: Interaction,
    pdfUrl: string,
    radius: number
  }
) => {
  const { podStore, sessionStore, uiStore } = useStore()
  // get the length of time this interaction has been viewed
  let dSeen = null
  const interactionType = item.interactionType
  let color = uiStore.getInteractionColor(interactionType as InteractionType)
  if(item.interactionType === "annotation" && item.style.color) color = item.style.color

  if(interactionType === 'comment' ||
    interactionType === 'link' ||
    interactionType === 'weblink' ||
    interactionType === 'tagging' ||
    interactionType === 'emotion' ||
    interactionType === 'readingQuestion') {

      // own interactions are always read
    dSeen = podStore.getView(interactionType, item.interactionId, null).dSeen
  }
  // if comment: search for unseen messages
  if(interactionType === "comment") {
    const thread = podStore.getThread(item.interactionId)
    if(thread) {
      const lastMessageViewed = podStore.getLastUnseenMessage(thread)
      if(lastMessageViewed.messageId) {
        dSeen = podStore.getView("message", lastMessageViewed.messageId, null).dSeen
      }
    }
  }

  // open drawer, if interaction is clicked
  const handleClick = (interactionType: string, interactionId: string) => {
    navigate(`${pdfUrl}/${interactionType}/${interactionId}`, {replace: true})
    // if tagging: open tag sidebar
    if(interactionType === 'tagging') {
      uiStore.clearActiveSidebarTags()
      uiStore.setSidebarStatus('tag')
    }
  }

  let opacity = uiStore.hideInteractions[interactionType] && (dSeen || uiStore.hideInteractions.showUnseen) ? .2 : 1

  const UnseenBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      border: `2px solid #f8f8ff`,
      backgroundColor: "#5d89ea"
    }
  }))

  const iconStyle = {
    backgroundColor: color,
    borderRadius: interactionType === "annotation" ? "5px": "",
    color: "white",
    width: `${radius}px`,
    height: `${radius}px`,
    margin: "1px",
    "> svg":{fontSize: `${radius/2}px`},
    "&:hover": {color: color},
    opacity: (dSeen && dSeen > 0 ) ? opacity : 1
  }

  const questionIconStyle = {
    backgroundColor: "whitesmoke",
    color: color,
    width: `${radius}px`,
    height: `${radius}px`,
    margin: "1px",
    "> svg":{fontSize: `${radius+4}px`},
    "&:hover": {color: "#edba8a"},
    opacity: (dSeen && dSeen > 0 ) ? opacity : 1
  }

  const InteractionMarginalia = () => (
    <IconButton
      aria-label={interactionType}
      onClick={() => handleClick(interactionType, item.interactionId)}
      sx={(interactionType === "readingQuestion") ? questionIconStyle : iconStyle }
    >
      <InteractionIcon interactionType={item.interactionType} />
    </IconButton>
  )

  return (
    (dSeen === 0) ?
      <UnseenBadge anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} style={{opacity}} overlap="circular" variant="dot">
        <InteractionMarginalia />
      </UnseenBadge>
      :
      <InteractionMarginalia />
  )
}

export default observer(MarginaliaIcon)