import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { PodClass } from '../../classes/Pod'
import { Badge, Box, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { CreateNewFolder, UploadFile } from '@mui/icons-material'
import { Op_addFolder, Op_addPdfFile } from '../../../../types/Ops'
import { PdfFile, ContentNodeStatus } from '../../../../types/Content'
import { Folder as FolderType } from '../../../../types/Content'
import PdfFileLine from './Contents/PdfFile'
import Folder from './Contents/Folder'
import Prompt from '../Elements/Prompt'
import {env} from '../../api/api'
import alertStore, { alert } from "../../stores/alertStore"
import { PdfImportFileInfo } from "../../stores/PdfImportStore"


const Contents = ({pod, initScrollPos}: {pod: PodClass, initScrollPos: (tab: string) => void}) => {
  const { sessionStore, podStore, opStore, uiStore, uploadStore, pdfImportStore } = useStore()
  const { t } = useTranslation()
  const [ newFolderDialog, setNewFolderDialog ] = useState(false)
  const [ uploadingTracker, setUploadingTracker ] = useState<{nodeId:string, filename:string}[]>([])
  const [ isRendered, setIsRendered] = useState(false)

  const isAdmin = pod.isAllowed('deletePod', pod.podId)
  const inputFile = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  useLayoutEffect(() => {
    initScrollPos("contents")
  })

  // show blank page until elements are rendered
  if(isRendered === false) return null

  const addToUploadingTracker = (nodeId:string, filename:string) => {
    console.log(`addToUploadingTracker(${nodeId}, ${filename})`)
    if (uploadingTracker.findIndex(a => a.nodeId === nodeId) === -1) setUploadingTracker([...uploadingTracker, {nodeId, filename}])
  }
  const deleteFromUploadingTracker = (nodeId:string) => {
    console.log(`removeFromUploadingTracker(${nodeId})`)
    setUploadingTracker([...uploadingTracker.filter(a => a.nodeId !== nodeId)])
  }

  const createFolder = (folderName:string) => {

    if (folderName) {
      var weight = 0
      for (const nodeId in pod.content.pdfFiles) {
        weight = Math.max(weight, pod.content.pdfFiles[nodeId].weight)
      }

      const op:Op_addFolder = {
        op: 'addFolder',
        podId: pod.podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym || '',
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          folderId: sessionStore.createUuid(),
          name: folderName,
          weight: weight + 100,
          coid: null,
          tCreated: dayjs().unix(),
          tModified: dayjs().unix()
        }
      }

      opStore.doOp(op)
    }
  }

  const onChangeFile = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return false
    for (var i=0; i<e.target.files.length; i++) {
      const file = e.target.files[i]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          const entry: PdfImportFileInfo = {
            file: fileData,
            uploadName: file.name,
            shrimpData: {},
            status: 'pending',
          }
          pdfImportStore.addToQueue(entry)
        }
        e.target.value = ''
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const onChangeFile_OLD = async (event:any) => {
    event.stopPropagation()
    event.preventDefault()

    const timeout = 10 * 60
    const file = event.target.files[0];

    const nodeId = sessionStore.createUuid()

    if (true) {
      const form = new FormData()
      form.append('file', file)

      const filename = uiStore.restrictString(file.name.replace(/\.pdf$/, ''), 'filename')

      const op:Op_addPdfFile = {
        op: 'addPdfFile',
        podId: pod.podId,
        data: {
          nodeId,
          name: filename,
          description:'',
          folderId:'',
          userId: sessionStore.session.user.userId,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId || '',
          userName: podStore.userPseudonym || '',
          weight: 100 + Object.keys(pod.content.pdfFiles).reduce((acc, nodeId) => { return Math.max(acc, pod.content.pdfFiles[nodeId].weight) }, 0),
          hidden: true,
          size: file.size,
          hash: '',
          nofPages:0,
          status: 'scheduled',
          coid:null,
        }
      }

      const success = () => {
        deleteFromUploadingTracker(nodeId)
        opStore.doOp(op)
      }

      const failure = () => {
        deleteFromUploadingTracker(nodeId)
        alertStore.push(alert(t('Could not upload the file. Please try again later.'), 'error'))
      }


      var fileUploadQuery:any = false
      const reader = new FileReader()

      reader.onload = async () => {
        alertStore.push(alert(t('Uploading the file "{{filename}}."', { filename }), 'success'))
        addToUploadingTracker(nodeId, file.name)
        const fileBuffer:ArrayBuffer = reader.result as ArrayBuffer
        console.log(fileBuffer)
        if (fileBuffer) uploadStore.add(`${env.backendBaseUrl}/uploadPdf`, pod.podId, { assetId: nodeId }, fileBuffer, 'application/pdf', success, failure)
      }

      reader.onerror = function() {
        console.log(reader.error);
        alertStore.push(alert(t('Could not upload the file. Please try again later.' + ' ' + reader.error), 'error'))
        failure()
      }

      reader.readAsArrayBuffer(file)

      const element = document.getElementById('fileForm')
      if (element) {
        const formElement:HTMLFormElement = element as HTMLFormElement
        formElement.reset()
      }
    }
  }

  const visibleFileStates:ContentNodeStatus[] = ['complete', 'processing', 'scheduled' , 'broken']


  // generates view of folders and files
  const PodPanelElement = () => {
    // function: return files inside a folder
    const fileListing = (folderId: string) => {
      return pod.getPdfFiles().filter((file: PdfFile) => ((visibleFileStates.indexOf(file.status) > -1) && (!file.hidden || isAdmin || file.userId === sessionStore.session.user.userId) && (file.folderId === folderId))).sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((file: PdfFile) => {
        return (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    }
    // data tree
    const elements: object[] = []
    // files without folder
    pod.getPdfFiles().filter((file: PdfFile) => ((visibleFileStates.indexOf(file.status) > -1) && (!file.hidden || isAdmin || file.userId === sessionStore.session.user.userId) && (!file.folderId))).forEach((file: PdfFile) => {
      elements.push({
        weight: file.weight,
        obj: (
          <li key={file.nodeId}>
            <PdfFileLine pdfFile={file} />
          </li>
        )
      })
    })
    // folder with files inside
    pod.getFolders().filter((folder:FolderType) => (!folder.hidden || isAdmin)).forEach((folder: FolderType) => {
      elements.push({
        weight: folder.weight,
        obj: (
          <li key={folder.folderId}>
              <Folder isAdmin={isAdmin} folder={folder} fileListing={fileListing} />
          </li>
        )
      })
    })
    return (
      <ul style={{listStyleType: "none", padding: 5 }}>
          {elements.sort((a: any, b: any) => { if (a.weight<b.weight) return -1; if (a.weight>b.weight) return 1; return 0 }).map((element: any) => element.obj)}
      </ul>
    )
  }

  const PodPanel = observer(PodPanelElement)


  return <>
      <PodPanel />
      <Box style={{textAlign:'right', marginTop: 48}}>
      { !pod.isAllowed('addFolder') ? null : <IconButton size='medium' onClick={() => setNewFolderDialog(true)}>
          <CreateNewFolder fontSize='medium' />
        </IconButton>
      }
      { !pod.isAllowed('addPdfFile') ? null : <>
        <IconButton size='medium' onClick={(e:React.MouseEvent) => { inputFile.current?.click() } }>
          <Badge color="primary" badgeContent={uploadingTracker.length}>
            <UploadFile fontSize='medium' />
          </Badge>
        </IconButton>

        <div><form id="fileForm" encType='multipart/form-data'>
        <input id="fileField"
          type="file"
          ref={inputFile}
          style={{display: 'none'}}
          multiple={true}
          onChange={onChangeFile}
        /></form>
        </div>
        </>
      }
      </Box>
      <Prompt title={t('New Folder')} type={{type:'string', maxLength:48}} info={t('Please enter a name for the new folder') as string} open={newFolderDialog} handleClose={() => setNewFolderDialog(false)} onOk={createFolder} />
  </>
}

export default observer(Contents)