import dayjs from "dayjs"
import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { memo, useRef } from "react"
import { useStore } from "../../contexts/store"
import { PodClass } from "../../classes/Pod"
import { Interaction } from "../../../../types/Interaction"
import { Op_addMessage, Op_addThread } from "../../../../types/Ops"
import { Message as Messagetype, Thread as ThreadType } from "../../../../types/Message"

import ViewTracker from "./ViewTracker"
import { Virtuoso } from "react-virtuoso"
import BlockquoteBox from "./BlockquoteBox"
import MessageArea from "./MessageArea"
import NewMessage from "./NewMessage"
import Message from "./Message"
import { ScrollSeekPlaceholder } from "./Message"
import { Box } from "@mui/material"


const Thread = ({interaction, podId}:{interaction: Interaction, podId: string}) => {
  const { opStore, podStore, sessionStore, uiStore } = useStore()
  const { session } = sessionStore
  const interactionId = interaction.interactionId
  const virtuoso = useRef<any>(null)

  const pod: PodClass | null = podStore.pod
  if(pod === null) return null

  // get thread from actual selected comment
  const thread = interactionId ? podStore.getThread(interactionId) : null
  const lastMessageViewed = thread ? podStore.getLastUnseenMessage(thread): null

  // warning: thread.message.length needed to update store view (virtuoso does not work)
  const messages = (thread && thread.messages.length) ? thread.messages : []

  // get initial scroll position
  let initialTopMostItemIndex = 0
  if(thread && lastMessageViewed) {
    if(lastMessageViewed.index === null) initialTopMostItemIndex = thread.messages.length
    else initialTopMostItemIndex = lastMessageViewed.index
  }


  function submitInput(input: string) {
    let threadId = thread ? thread.threadId : null
    // create new thread, if this is the first reply
    if(thread === null) {
      threadId = `T.${interactionId}` // for pod-public threads, base the threadId on the interactionId
      opStore.doOp({
        op: "addThread",
        podId: podId,
        data: {
          coid: null,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          interactionId: interactionId,
          messages: [],
          threadName: "",
          tCreated: dayjs().unix(),
          tModified: dayjs().unix(),
          threadId: threadId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
        } as ThreadType
      } as Op_addThread)
    }
    // create message inside comment
    opStore.doOp({
      op: "addMessage",
      podId: podId,
      data: {
        usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        messageId: sessionStore.createUuid(),
        threadId: threadId,
        refMessageId: null,
        coid: null,
        userId: session.user.userId,
        userName: podStore.userPseudonym,
        text: input,
        reactions: {},
        tCreated: dayjs().unix(),
        tModified: dayjs().unix()
      } as Messagetype
    } as Op_addMessage)
  }

  const handleScrolling = () => {
    // hide edit message field if user scrolls
    if(uiStore.editedMessageId) uiStore.setEditedMessageId(null)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "auto max-content"}}>
      <Box>
        <Virtuoso
          atBottomThreshold={350}
          // @ts-ignore
          components={{ Header: Header, ScrollSeekPlaceholder }}
          context={{ interaction, lastMessageViewed, messages, podId }}
          data={messages}
          followOutput={(atBottom: boolean) => {
            if (atBottom) {
              return 'smooth'
            } else {
              return false
            }
          }}
          initialTopMostItemIndex={initialTopMostItemIndex}
          isScrolling={handleScrolling}
          itemContent={(_, message) => {
            // include new message element
            if(lastMessageViewed && lastMessageViewed.messageId && lastMessageViewed.messageId === message.messageId) {
              return (
                <>
                  <NewMessage key={`${message.messageId} new message`} />
                  <Message message={message} interactionId={interaction.interactionId} usergroupId={thread?.usergroupId} />
                </>
              )
            }
            // show message
            return (
              <Message message={message} interactionId={interaction.interactionId} usergroupId={thread?.usergroupId} />
            )
          }}
          ref={virtuoso}
          scrollSeekConfiguration={{
            enter: (velocity) => Math.abs(velocity) > 50,
            exit: (velocity) => {
              const shouldExit = Math.abs(velocity) < 10
              return shouldExit
            }
          }}
        />
      </Box>
      <ViewTracker type={interaction.interactionType} id={interactionId}>
      <MessageArea
        disabled={!(pod.isAllowed('addMessage') && (pod.isAllowed('addThread') || thread))}
        key={interactionId}
        placeholder={(t('reply')+" ...")}
        submitInput={submitInput}
        value=""
      />
      </ViewTracker>
    </Box>
  )
}

export default observer(Thread)

const Header = memo(({context: {interaction}}: { context: { interaction: Interaction }}) => (
  <BlockquoteBox interaction={interaction} />
), isEqual)

function isEqual(prevProps: any, nextProps: any) {
  if(prevProps && prevProps.context && prevProps.context.interaction &&
     nextProps && nextProps.context && nextProps.context.interaction &&
     prevProps.context.interaction.interactionId === nextProps.context.interaction.interactionId) {
      return false
  }
  return true
}
