import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { Box, Button, Checkbox, Divider, FormControlLabel, Switch } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStore } from '../../../contexts/store'
import Prompt from "../../Elements/Prompt"
import { PodClass } from "../../../classes/Pod"
import { Op_editPod } from "../../../../../types/Ops"
import { downloadableType } from "../../../../../types/Miscs"
import dayjs from "dayjs"
import { ImageUploadbutton } from "../../Elements/ImageUploadButton"


// message from thread
const Appearance = () => {

  const { podStore, opStore, sessionStore, uploadStore } = useStore()
  const isAdmin:boolean = podStore.pod?.isAllowed('deletePod', podStore.pod?.podId) ? true : false

  const [showPodNamePrompt, setShowPodNamePrompt] = useState<boolean>(false)
  const [showPodDescriptionPrompt, setShowPodDescriptionPrompt] = useState<boolean>(false)
  const [showPodKeyphrasePrompt, setShowPodKeyphrasePrompt] = useState<boolean>(false)

  const { t } = useTranslation()
  const session = sessionStore.session
/*
  useLayoutEffect(() => {
    initScrollPos("settings")
  })
*/

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  const changePodSetting = (which:'name'|'description'|'keyphrase', s:string) => {
    if (s) {
      const mods: {
        name?: string,
        description?: string,
        allowDownload?:downloadableType[],
        keyphrase?: string,
        tModified?: number
      } = {
        tModified: dayjs().unix()
      }
      mods[which] = s

      const op:Op_editPod = {
        op: 'editPod',
        podId: pod.podId,
        data: {
          mods,
          userId: session.user.userId,
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userName: podStore.userPseudonym || '',
        }
      }

      opStore.doOp(op)
    }
  }

  const op:Op_editPod = {
    op: 'editPod',
    podId: pod.podId,
    data: {
      mods: {  },
      userId: session.user.userId,
      usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
      userName: podStore.userPseudonym || '',
    }
  }

  const podImageUploadSuccess = (fileId:string) => {
    op.data.mods.podImageFileId = fileId
    console.log(op)
    opStore.doOp(op)
  }

  const podImageUploadFailure = () => {
    console.log('failure')
  }


  return <Box>
          <Box sx={{mt:1, mb:1}}>
            <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodNamePrompt(true)}}>{t('Change Pod Name')}</Button>&nbsp;
          </Box>
          <Divider />
          <Box sx={{mt:1, mb:1}}>
            <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodDescriptionPrompt(true)}}>{t('Change Pod Description')}</Button>&nbsp;
          </Box>
          <Divider />
          <Box sx={{mt:1, mb:1}}>
            <Button variant='outlined' disabled={!pod.permissions['editPod']} onClick={() => {setShowPodKeyphrasePrompt(true)}}>{t('Change Pod Keyphrase')}</Button>
          </Box>
          <Divider />
          <Box sx={{mt:1, mb:1}}>
            <ImageUploadbutton
              disabled={!pod.permissions['editPod']}
              type="podImage"
              fileId={pod.podImageFileId}
              success={podImageUploadSuccess}
              failure={podImageUploadFailure}
              width={1280}
              height={1280}
            />
          </Box>
          <Prompt title={t('Pod Name')} info={t('Please enter a new name for this pod') as string} open={showPodNamePrompt} handleClose={() => { setShowPodNamePrompt(false) }} onOk={(s:string) => { changePodSetting('name', s) }} type={{type:'string', maxLength:128}} defaultValue={pod.name} />
          <Prompt title={t('Pod Description')} info={t('Please enter a new name for this pod') as string} open={showPodDescriptionPrompt} handleClose={() => { setShowPodDescriptionPrompt(false) }} onOk={(s:string) => { changePodSetting('description', s) }} type={{type:'string', maxLength:255}} defaultValue={pod.description} />
          <Prompt title={t('Pod Keyphrase')} info={t('Please enter a new keyphrase for this pod') as string} open={showPodKeyphrasePrompt} handleClose={() => { setShowPodKeyphrasePrompt(false) }} onOk={(s:string) => { changePodSetting('keyphrase', s) }} type={{type:'string', maxLength:128}} />
        </Box>
}

export default observer(Appearance)
