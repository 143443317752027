import { Box } from "@mui/material"

const CommentSidebar = () => {
  return (
    <Box sx={{width: "250px"}}>
      <Box>
        Comment Sidebar
      </Box>
      Cooming soon ...
    </Box>
  )
}

export default CommentSidebar