import { useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import React from "react"

import { PodClass } from "../../classes/Pod"
import { useStore } from '../../contexts/store'

import Subtabs from "../Elements/Subtabs"
import ContentImporter from "./Settings/ContentImporter"
import Permissions from "./Settings/Permissions"
import Appearance from "./Settings/Appearance"

const Settings = ({ initScrollPos, activeSubTabIndex }: {initScrollPos: (tab: string) => void, activeSubTabIndex?:string}) => {
  const { podStore, sessionStore } = useStore()
  const isAdmin:boolean = podStore.pod?.isAllowed('deletePod', podStore.pod?.podId) ? true : false

  const { t } = useTranslation()
  const session = sessionStore.session

  useLayoutEffect(() => {
    initScrollPos("settings")
  })

  const pod:PodClass|null = podStore.pod
  if (!pod) return null

  return <Box className={"overflowWrapper"}>
    <Subtabs context="settings" activeKey={activeSubTabIndex} tabs={[
      {
        key:'appearance',
        label: 'Appearance',
        content: <Appearance />
      },{
        key:'permisisons',
        label: 'Permissions',
        content: <Permissions />
      },{
        key:'export',
        label: 'Export&Share',
        content: <div>Export and Share: Coming Soon</div>
      },
    ]} />
  </Box>

}

export default observer(Settings)